import { useState } from 'react';

import { EnumsGamePackChangeLevel } from '@lp-lib/api-service-client/public';

export function GamePackChangeConfirm(props: {
  onClick: (level: EnumsGamePackChangeLevel, note?: string) => void;
}) {
  const [level, setLevel] = useState<EnumsGamePackChangeLevel | null>(null);
  return (
    <div
      className='flex flex-col border border-lp-gray-003 rounded-1.5lg bg-modal p-1 relative'
      onPointerLeave={() => setLevel(null)}
    >
      <button
        type='button'
        className='btn w-40 h-6.5 text-white text-left text-xs font-light hover:bg-lp-gray-003 rounded px-2'
        onClick={() =>
          props.onClick(EnumsGamePackChangeLevel.GamePackChangeLevelMinor)
        }
        onPointerEnter={() =>
          setLevel(EnumsGamePackChangeLevel.GamePackChangeLevelMinor)
        }
      >
        💾 Save Minor Update
      </button>
      <button
        type='button'
        className='btn w-40 h-6.5 text-white text-left text-xs font-light hover:bg-lp-gray-003 rounded px-2'
        onClick={() =>
          props.onClick(EnumsGamePackChangeLevel.GamePackChangeLevelMajor)
        }
        onPointerEnter={() =>
          setLevel(EnumsGamePackChangeLevel.GamePackChangeLevelMajor)
        }
      >
        🔼 Save Version Upgrade
      </button>
      {level && (
        <div
          className='w-60 h-full border border-lp-gray-003 rounded-1.5lg 
        bg-modal text-white text-2xs font-light p-1 absolute -left-1 top-0 
        transform -translate-x-full flex items-center justify-center'
        >
          {level === EnumsGamePackChangeLevel.GamePackChangeLevelMinor ? (
            <p>
              Changes that do not affect the gameplay such as fixing typo,
              updating tags.
            </p>
          ) : (
            <p>
              Changes that make gameplay different, such as adding/removing
              blocks. Adding new blocks will remove the game pack from user's
              play history.
            </p>
          )}
        </div>
      )}
    </div>
  );
}
